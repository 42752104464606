<template>
  <v-treeview
    v-bind="Entorno"
    :items="itemsMenu"
    activatable
    :active.sync="activeMenu"
    hoverable
    :open.sync="open"
    color="#ddd"
    open-on-click
    transition>

      <!-- <template v-slot:prepend="{ item }">
        <v-icon>{{ `mdi-${item.icon}` }}</v-icon>
      </template> -->

    <!-- <template v-slot:prepend="{ item }">
      <v-icon small v-if="item.icon>''">{{ item.icon + '-outline' }}</v-icon>
    </template> -->

    <!-- icono -->
    <template v-slot:prepend="{ item, open, active }">
      <v-icon small v-if="item.icon>''" :color="active ? 'red' : ''">
        {{ item.icon + '-outline' }}
      </v-icon>
      <v-icon small v-else>
        {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
      </v-icon>
    </template>

    <!-- label -->
    <!-- <template v-slot:label="{ item }">
      {{ item.name }}
    </template> -->

  </v-treeview>
</template>



<script>
export default {
  data() {
    return {
      Entorno: this.$cfg.app.menu,
      moduloSeleccionado: [],
      open:[],
      active:[]
    };
  },

  methods: {

    // devuelvo objeto con el nombre del componente route y sus parametros
    get_datafromPath(comp) {
      let routeMenu = this.$router.options.routes.find(
        elem => elem.name === comp
      );

      if (!routeMenu) return { name: comp };
      return { name: comp, params: routeMenu.params ? routeMenu.params : {}, path:routeMenu.path };
    },
  },


  computed: {
    // devuelvo items de menú
    itemsMenu() {
      console.log("<<<<<<<<<<<<<<<<<<<<  itemsMenu() en AppMenu");

      // con el array de módulos monto un array anidado con los padres y sus hijos
      var itemsMenu = JSON.parse(JSON.stringify(this.$store.state.A_menu));
      if (!itemsMenu) return;

      itemsMenu.forEach((it, key) => {
          itemsMenu[key].id= key;
          (itemsMenu[key].pid= it.menuId.length === 2? null : itemsMenu.find(elem => elem.menuId === it.menuId.slice(0, -2)).menuId)
      });

      const anida = (items, menuId = null, link = "pid") =>
        items
          .filter(item => item[link] === menuId && item.tipo!= "Mod")
          .map(item => ({ ...item, children: anida(items, item.menuId) }));

      return anida(itemsMenu);
    },


    activeMenu: {
      get() {
        return this.$store.getters['get_activeMenu'];
      },

      set(val) {
        this.$store.commit("set_activeMenu", val);
      }
    }

  },


  watch: {

    // detecta cuando cambia el elemento seleccionado del treeview
    activeMenu (arr) {
      if (arr.length) {
        //this.$router.push({ path: this.get_datafromPath(arr[0].comp).path });
        this.$router.push({ path: this.get_datafromPath(arr[0].comp).path }).catch(()=>{});
      } else {
        //this.$router.push({ path: '/' });
        this.$router.push({ path: '/' }).catch(()=>{});
      }
    },

  },

};
</script>

<style scope>
.v-application a {
  font-style: bold;
  color: white;
  text-decoration: none;
}
</style>
